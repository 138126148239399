<template>
    <b-container class="text-center">
		<b-row>
			<b-col>
				<h1>Unustasid parooli?</h1>
			</b-col>
		</b-row>
		<b-row>
			<b-col>
				<b-alert v-if="error" show variant="danger"><em>{{ error }}</em></b-alert>
				<b-alert v-if="message" show variant="success"><em>{{ message }}</em></b-alert>
			</b-col>
		</b-row>
		<b-row class="justify-content-center">
			<b-col sm="30" v-if="!message">
				<b-form @submit.prevent="sendReminder" v-if="!spinner">
					<b-form-input type="email" name="email" placeholder="email" v-model="email" class="mb-2"></b-form-input>
					<b-button @click="sendReminder" variant="primary">
						Saada link
					</b-button>
				</b-form>
				<div v-else class="text-center text-primary my-2">
					<b-spinner variant="primary" class="align-middle"></b-spinner>
				</div>
			</b-col>
		</b-row>
    </b-container>
</template>

<script>
    export default {
        data () {
            return {
                email: '',
				error: false,
				spinner: false,
				message: false,
            }
        },

        methods: {
            sendReminder () {
            	this.error = false;
            	this.spinner = true;
                this.$store
                    .dispatch('remindPassword', {
                        email: this.email
                    })
                    .then(() => {
						this.error = false;
						this.spinner = false;
						this.message = 'Sinu e-mailile ' + this.email + ' saadeti parooli taastamise link.';
						this.email = '';
                    })
                    .catch(err => {
						this.spinner = false;
						this.error = 'Viga: Sellise emailiga kasutajat ei leitud!';
                    })
            },
        }
    }
</script>
