<template>
    <b-container class="text-center">
		<b-row>
			<b-col>
				<h1>Login</h1>
			</b-col>
		</b-row>
		<b-row>
			<b-col>
				<b-alert v-if="loginError" show variant="danger"><em>{{ loginError }}</em></b-alert>
			</b-col>
		</b-row>
		<b-row class="justify-content-center">
			<b-col sm="30">
				<b-form @submit.prevent="login">
					<b-form-input type="email" name="email" v-model="email" class="mb-2"></b-form-input>
					<b-form-input type="password" name="password" v-model="password" class="mb-2"></b-form-input>
					<b-button @click="login" variant="primary">
						Logi sisse
					</b-button>
					<div class="mt-4">
						<b-link to="/forgot-password">Unustasid parooli?</b-link>
					</div>
				</b-form>
			</b-col>
		</b-row>
    </b-container>
</template>

<script>
    export default {
        data () {
            return {
                email: '',
                password: '',
				loginError: false,
            }
        },

        methods: {
            login () {
            	this.loginError = false;
                this.$store
                    .dispatch('login', {
                        email: this.email,
                        password: this.password
                    })
                    .then(() => {
                        this.$router.push({ path: '/transactions' });
                    })
                    .catch(err => {
                        this.loginError = 'Viga: Sellise kasutajanime ja parooliga kasutajat ei leitud!';
                    })
            },
        }
    }
</script>
